<template>
  <div class="home">
    <div class="pc hidden-sm">
      <div class="one flex">
        <div class="left">
          <div class="logo"><img src="../assets/logo.png" alt="" /></div>
          <div class="banner"><img src="../assets/banner.png" alt="" /></div>
        </div>
        <div class="flex1">
          <div class="right">
            <div class="title">
              <p>所有書籍</p>
              <p>永遠免費閱讀</p>
            </div>
            <div class="desc">
              <p>
                BookTok提供海量熱門網絡小說，讓你无需花费一分钱，隨時隨地找到想看的小說
              </p>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.newenorthwestwolf.booktok"
            >
              <div class="btn">下載</div>
            </a>
          </div>
        </div>
      </div>
      <div class="two">
        <div class="flex">
          <div class="left">
            <div class="title">海量書籍</div>
            <div class="desc">各種網絡紅文盡情暢覽</div>
          </div>
          <div class="right flex1">
            <img src="../assets/two.png" alt="" />
          </div>
        </div>
      </div>
      <div class="three">
        <div class="container">
          <div class="title">推薦書單</div>
          <div class="desc">適合你的才是最好的</div>
          <div class="pic flex">
            <div class="big"><img src="../assets/pc1.png" alt="" /></div>
            <div class="flex1">
              <div class="small"><img src="../assets/pc2.png" alt="" /></div>
              <div class="small"><img src="../assets/pc3.png" alt="" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="four flex">
        <div class="img"><img src="../assets/four.png" alt="" /></div>
        <div class="flex1">
          <div class="info">
            <div class="title">精選分類</div>
            <div class="desc">網游、玄幻、仙俠... 你想看的全都有</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="agree-items">
          <router-link to="/agree/zh">用户协议</router-link>
          <div class="item-line"></div>
          <router-link to="/privacy/zh">隐私条款</router-link>
        </div>
        <div class="flex">
          <div class="flex1">
            Facebook：<a href="http://www.facebook.com/Booktok-262994765547662"
          >www.facebook.com/Booktok-262994765547662</a
          >
          </div>
          <div class="flex1">
            Twitter：<a href="http://www.twitter.com/Booktokofficial"
          >www.twitter.com/Booktokofficial</a
          >
          </div>
          <div class="flex1">Gmail：Booktokapp@gmail.com</div>
        </div>
      </div>
    </div>

    <div class="mb hidden-md">
      <div class="one">
        <div class="container">
          <div class="logo"><img src="../assets/logo.png" alt="" /></div>
          <div class="banner"><img src="../assets/banner.png" alt="" /></div>
          <div class="title">
            <p>所有書籍</p>
            <p>永遠免費閱讀</p>
          </div>
          <div class="desc">
            <p>
              BookTok提供海量熱門網絡小說，讓你无需花费一分钱，隨時隨地找到想看的小說
            </p>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.newenorthwestwolf.booktok"
          >
            <div class="btn">下載</div>
          </a>
        </div>
      </div>
      <div class="two">
        <div class="img_area">
          <img src="../assets/two.png" alt="" />
        </div>
        <div class="title">海量書籍</div>
        <div class="desc">各種網絡紅文盡情暢覽</div>
      </div>
      <div class="three">
        <div class="pic_area">
          <div class="pic"><img src="../assets/pc1.png" alt="" /></div>
          <div class="pic"><img src="../assets/pc2.png" alt="" /></div>
          <div class="pic"><img src="../assets/pc3.png" alt="" /></div>
        </div>
        <div class="text_area">
          <div class="title">推薦書單</div>
          <div class="desc">適合你的才是最好的</div>
        </div>
      </div>
      <div class="four">
        <div class="img"><img src="../assets/four.png" alt="" /></div>
        <div class="title">精選分類</div>
        <div class="desc">網游、玄幻、仙俠... 你想看的全都有</div>
      </div>
      <div class="footer">
        <div class="agree-items">
          <router-link to="/agree/zh">用户协议</router-link>
          <div class="item-line"></div>
          <router-link to="/privacy/zh">隐私条款</router-link>
        </div>
        <div class="flex1">
          Twitter：<a href="http://www.twitter.com/Booktokofficial"
            >www.twitter.com/Booktokofficial</a
          >
        </div>
        <div class="flex1">Gmail：Booktokapp@gmail.com</div>
        <div class="flex1">
          Facebook：<a href="http://www.facebook.com/Booktok-262994765547662"
            >www.facebook.com/Booktok-262994765547662</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {},
};
</script>
<style scoped lang="scss">
.home {
  .pc {
    .one {
      min-height: 1080px;
      background: linear-gradient(180deg, #ffd453 0%, #ffffff 100%);

      .left {
        padding-top: 3vw;
        padding-left: 11.4vw;
        padding-right: 4.5vw;
        font-size: 0;
        .logo {
          margin-bottom: 3.5vw;
          text-align: left;
        }
      }

      .right {
        width: 100%;
        height: 52.5vw;
        background-image: url(../assets/banner_bg.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        padding-top: 13.5vw;
        padding-left: 4.6vw;
        .title {
          p {
            font-family: Helvetica-Bold, Helvetica;
            text-align: left;
            font-size: 60px;
            font-weight: bold;
            color: #333333;
          }
        }
        .desc {
          margin-top: 2.5vw;
          max-width: 40.5vw;
          p {
            font-size: 30px;
            font-family: Helvetica;
            color: #666666;
            line-height: 40px;
            text-align: left;
          }
        }
        .btn {
          margin-top: 5.5vw;
          width: 15vw;
          height: 4.5vw;
          background: #333333;
          border-radius: 20px;

          font-size: 36px;
          font-family: Helvetica-Bold, Helvetica;
          color: #ffffff;
          font-weight: bold;
          line-height: 4.5vw;
        }
      }
    }
    .two {
      // background: #ccc;
      padding-top: 6.2vw;
      padding-left: 11.4vw;
      padding-bottom: 4vw;
      .left {
        padding-right: 8vw;
        .title {
          font-size: 60px;
          font-weight: bold;
          color: #333333;
          text-align: left;
          margin-top: 9vw;
        }
        .desc {
          max-width: 35.4vw;
          font-size: 45px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #999999;
          line-height: 70px;
          text-align: left;
          margin-top: 0.5vw;
        }
      }
      .right {
        padding-right: 11vw;
        img {
          width: 100%;
          max-width: 638px;
        }
      }
    }
    .three {
      padding: 0 3vw;
      .container {
        height: 1300px;
        background-image: url(../assets/banner_bg3.png);
        background-position: top left;
        background-repeat: no-repeat;
        padding-top: 17vw;
        padding-left: 8vw;
        .title {
          font-family: Helvetica-Bold, Helvetica;
          text-align: left;
          font-size: 60px;
          font-weight: bold;
          color: #333333;
        }
        .desc {
          font-size: 45px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #999999;
          line-height: 70px;
          text-align: left;
          margin-top: 0.5vw;
        }
        .pic {
          margin-top: 4vw;
          .big {
            margin-right: 2.5vw;
            max-width: 50vw;
            font-size: 0;
            img {
              width: 100%;
            }
          }
          .small {
            &:nth-of-type(1) {
              margin-bottom: 1.8vw;
            }
            max-width: 24.4vw;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .four {
      // background: #ccc;
      padding: 7.2vw 11.4vw;
      .img {
        margin-right: 5.5vw;
        max-width: 32vw;
        font-size: 0;
        img {
          width: 100%;
        }
      }
      .info {
        margin-top: 5vw;
        .title {
          font-family: Helvetica-Bold, Helvetica;
          text-align: left;
          font-size: 60px;
          font-weight: bold;
        }
        .desc {
          font-size: 45px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #999999;
          line-height: 70px;
          text-align: left;
          margin-top: 0.5vw;
        }
      }
    }
    .footer {
      height: 100px;
      background: #ffd453;
      border-radius: 100px 100px 0px 0px;
      padding-top: 10px;

      div {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
      }
    }
    .agree-items{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      a{
        text-decoration: none;
        color: #333333;
        line-height: 30px;
      }
      .item-line{
        width: 2px;
        background: #333;
        height: 40%;
        border-radius: 2px;
        margin: 0 10px;
      }
    }
  }

  /*750 尺寸 以上 的屏幕样式*/
  @media (min-width: 769px) {
    .hidden-md {
      display: none;
    }
  }

  /*750 尺寸 以下 的屏幕样式*/
  @media (max-width: 768px) {
    .hidden-sm {
      display: none;
    }

    .mb {
      .one {
        width: 100%;
        height: 177vw;
        background: linear-gradient(180deg, #ffd453 0%, #ffffff 100%);
        .container {
          padding: 0 5.3vw;
          background-image: url(../assets/banner_bg.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;

          .logo {
            padding-top: 5.3vw;
            img {
              max-width: 38.5vw;
            }
          }
          .banner {
            padding-top: 5vw;
            img {
              max-width: 48.4vw;
            }
          }
          .title {
            font-size: 25px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #333333;
            line-height: 8vw;
            text-align: left;
            p {
              margin-bottom: 0.8vw;
            }
          }
          .desc {
            padding-top: 1.3vw;
            font-size: 15px;
            font-family: Helvetica;
            color: #666666;
            line-height: 5.3vw;
            text-align: left;
          }
          a {
            margin-top: 6.5vw;
            display: inline-block;
            .btn {
              width: 38vw;
              height: 11.5vw;
              background: #333333;
              border-radius: 10px;

              font-size: 18px;
              font-family: Helvetica-Bold, Helvetica;
              color: #ffffff;
              font-weight: bold;
              line-height: 11.5vw;
            }
          }
        }
      }
      .two {
        padding: 21vw 11vw;
        padding-bottom: 0;
        .img_area {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .title {
          margin-top: 20vw;
          font-size: 25px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #333333;
          line-height: 8vw;
          text-align: left;
        }
        .desc {
          margin-top: 1.3vw;
          font-size: 20px;
          font-weight: bold;
          color: #999999;
          line-height: 8vw;
          text-align: left;
        }
      }
      .three {
        min-height: 120vw;
        background-image: url(../assets/banner_bg3.png);
        background-position: top left;
        background-size: 130%;
        background-repeat: no-repeat;
        .pic_area {
          padding: 0 10vw;
          padding-top: 40vw;
          .pic {
            font-size: 0;
            margin-bottom: 6.5vw;
            img {
              width: 100%;
            }
          }
        }
        .text_area {
          padding: 0 6.5vw;
          padding-bottom: 13.3vw;
          .title {
            margin-top: 20vw;
            font-size: 25px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #333333;
            line-height: 8vw;
            text-align: left;
          }
          .desc {
            margin-top: 1.3vw;
            font-size: 20px;
            font-weight: bold;
            color: #999999;
            line-height: 8vw;
            text-align: left;
          }
        }
      }
      .four {
        padding: 13.3vw 8.5vw;

        .img {
          width: 100%;
          font-size: 0;
          img {
            width: 100%;
          }
        }
        .title {
          margin-top: 20vw;
          font-size: 25px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #333333;
          line-height: 8vw;
          text-align: left;
        }
        .desc {
          margin-top: 1.3vw;
          font-size: 20px;
          font-weight: bold;
          color: #999999;
          line-height: 8vw;
          text-align: left;
        }
      }
      .footer {
        height: 28vw;
        background: #ffd453;
        border-radius: 50px 50px 0px 0px;
        padding-top: 1.5vw;

        div {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 5.5vw;
        }
        .agree-items{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          line-height: 30px;
          .item-line{
            width: 2px;
            height: 40%;
            background: #333333;
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
