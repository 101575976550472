var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"pc hidden-sm"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"agree-items"},[_c('router-link',{attrs:{"to":"/agree/zh"}},[_vm._v("用户协议")]),_c('div',{staticClass:"item-line"}),_c('router-link',{attrs:{"to":"/privacy/zh"}},[_vm._v("隐私条款")])],1),_vm._m(4)])]),_c('div',{staticClass:"mb hidden-md"},[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"agree-items"},[_c('router-link',{attrs:{"to":"/agree/zh"}},[_vm._v("用户协议")]),_c('div',{staticClass:"item-line"}),_c('router-link',{attrs:{"to":"/privacy/zh"}},[_vm._v("隐私条款")])],1),_vm._m(9),_c('div',{staticClass:"flex1"},[_vm._v("Gmail：Booktokapp@gmail.com")]),_vm._m(10)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/banner.png"),"alt":""}})])]),_c('div',{staticClass:"flex1"},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v("所有書籍")]),_c('p',[_vm._v("永遠免費閱讀")])]),_c('div',{staticClass:"desc"},[_c('p',[_vm._v(" BookTok提供海量熱門網絡小說，讓你无需花费一分钱，隨時隨地找到想看的小說 ")])]),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.newenorthwestwolf.booktok"}},[_c('div',{staticClass:"btn"},[_vm._v("下載")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"title"},[_vm._v("海量書籍")]),_c('div',{staticClass:"desc"},[_vm._v("各種網絡紅文盡情暢覽")])]),_c('div',{staticClass:"right flex1"},[_c('img',{attrs:{"src":require("../assets/two.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_vm._v("推薦書單")]),_c('div',{staticClass:"desc"},[_vm._v("適合你的才是最好的")]),_c('div',{staticClass:"pic flex"},[_c('div',{staticClass:"big"},[_c('img',{attrs:{"src":require("../assets/pc1.png"),"alt":""}})]),_c('div',{staticClass:"flex1"},[_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":require("../assets/pc2.png"),"alt":""}})]),_c('div',{staticClass:"small"},[_c('img',{attrs:{"src":require("../assets/pc3.png"),"alt":""}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four flex"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/four.png"),"alt":""}})]),_c('div',{staticClass:"flex1"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v("精選分類")]),_c('div',{staticClass:"desc"},[_vm._v("網游、玄幻、仙俠... 你想看的全都有")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex1"},[_vm._v(" Facebook："),_c('a',{attrs:{"href":"http://www.facebook.com/Booktok-262994765547662"}},[_vm._v("www.facebook.com/Booktok-262994765547662")])]),_c('div',{staticClass:"flex1"},[_vm._v(" Twitter："),_c('a',{attrs:{"href":"http://www.twitter.com/Booktokofficial"}},[_vm._v("www.twitter.com/Booktokofficial")])]),_c('div',{staticClass:"flex1"},[_vm._v("Gmail：Booktokapp@gmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":""}})]),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/banner.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_c('p',[_vm._v("所有書籍")]),_c('p',[_vm._v("永遠免費閱讀")])]),_c('div',{staticClass:"desc"},[_c('p',[_vm._v(" BookTok提供海量熱門網絡小說，讓你无需花费一分钱，隨時隨地找到想看的小說 ")])]),_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=com.newenorthwestwolf.booktok"}},[_c('div',{staticClass:"btn"},[_vm._v("下載")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two"},[_c('div',{staticClass:"img_area"},[_c('img',{attrs:{"src":require("../assets/two.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("海量書籍")]),_c('div',{staticClass:"desc"},[_vm._v("各種網絡紅文盡情暢覽")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three"},[_c('div',{staticClass:"pic_area"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/pc1.png"),"alt":""}})]),_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/pc2.png"),"alt":""}})]),_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":require("../assets/pc3.png"),"alt":""}})])]),_c('div',{staticClass:"text_area"},[_c('div',{staticClass:"title"},[_vm._v("推薦書單")]),_c('div',{staticClass:"desc"},[_vm._v("適合你的才是最好的")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/four.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("精選分類")]),_c('div',{staticClass:"desc"},[_vm._v("網游、玄幻、仙俠... 你想看的全都有")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex1"},[_vm._v(" Twitter："),_c('a',{attrs:{"href":"http://www.twitter.com/Booktokofficial"}},[_vm._v("www.twitter.com/Booktokofficial")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex1"},[_vm._v(" Facebook："),_c('a',{attrs:{"href":"http://www.facebook.com/Booktok-262994765547662"}},[_vm._v("www.facebook.com/Booktok-262994765547662")])])
}]

export { render, staticRenderFns }