import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: "/agree/:lang",
    name: "agree",
    component: () => import('../views/Agree.vue')
  },
  {
    path: "/privacy/:lang",
    name: "privacy",
    component: () => import('../views/Privacy.vue')
  },
  {
    path: "/member/:lang",
    name: "member",
    component: () => import('../views/Member.vue')
  },
  {
    path: "/renew/:lang",
    name: "renew",
    component: () => import('../views/Renew.vue')
  },
  {
    path: "/hexagon",
    name: "hexagon",
    component: () => import('../views/Hexagon.vue')
  },
  {
    path: "/delaccount",
    name: "delaccount",
    component: () => import('../views/DelAccount.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
